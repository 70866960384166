import { storage } from '~/utils/storage';

const SESSION_KEY = 'SESSION';

export const getSavedSession = () => {
  return storage.get(SESSION_KEY);
};

export const saveSession = (session) => {
  return storage.set(SESSION_KEY, session);
};

export const deleteSession = () => {
  return storage.remove(SESSION_KEY);
};
