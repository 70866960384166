import React from 'react';

const LoadingIndicator = ({ className }) => {
  return (
    <div className={['spinner', className].filter(Boolean).join(' ')}>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default LoadingIndicator;
