import React from 'react';

const Fallback = () => {
  return (
    <div
      style={{
        height: '100vh',
        width: '100%',
      }}
    />
  );
};

export default Fallback;
